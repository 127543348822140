/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { Container, Layout, Link, Tooltip } from '../components';
import { InfoIcon } from '../components/Icon';
import MapAndFilters from '../map/MapAndFilters';
import combineProps from '../parcelFlow/combineServiceProps';
import { resetBasket } from '../state/basket';
import { imagePathForProduct, requiresMHorAgentToSend, weightText4product, SizeCode } from '../state/catalog';
import { showNotification } from '../state/notifications';
import * as analytics from '../utils/analytics';
import * as api from '../utils/api';
import getLanguage, { useTranslate } from '../utils/getLanguage';
import { getOrderId, getTransactionId } from '../utils/order';
import * as streetAndApartment from '../utils/streetAndApartment';
import { capitalize } from '../utils/string';

const largeSizeCodes = ['Rengassetti', 'Polkupyörä', 'XXL Flex'];

function serviceCodesForShipments(shipments) {
  let requiresMHorAgent = false;
  let atleastOneIsLarge = false;
  let atleastOneIs35KgOrSpecial = false;
  let atleastOneExpressParcel = false;
  shipments.forEach(s => {
    const { product, services } = s;
    const itmProps = combineProps(product, services);
    if (itmProps.serviceCode === '21') {
      atleastOneExpressParcel = true;
    }
    if (requiresMHorAgentToSend(product, services)) {
      requiresMHorAgent = true;
    }
    if (itmProps.weightClass > 30) {
      atleastOneIs35KgOrSpecial = true;
    }
    if (largeSizeCodes.includes(itmProps.sizeCode)) {
      atleastOneIs35KgOrSpecial = true;
    }
    if (product.isLarge) {
      atleastOneIsLarge = true;
    }
  });
  // todo? more P4 logic

  if (atleastOneIs35KgOrSpecial) {
    return ['P35'];
  } else if (atleastOneExpressParcel) {
    return ['P3'];
  } else if (requiresMHorAgent) {
    return ['P3'];
  } else if (atleastOneIsLarge) {
    return ['P2', '-EP'];
  } else {
    return ['P2'];
  }
}

export default ({ pageContext, location: { state: pageState } }) => {
  analytics.usePageCategory('paketit');
  const translate = useTranslate();
  const dispatch = useDispatch();
  const basket = useSelector(state => state.basket) || {};
  const [order, setOrder] = useState(cloneDeep(pageState && pageState.order));

  const fetchOrder = useCallback(
    async (orderId, transactionId) => {
      let order;
      try {
        order = await api.getOrder(orderId, transactionId);
      } catch (error) {
        console.error('Failed to get order:', error);
        dispatch(showNotification('Ehkä onnistui ehkä ei')); // todo
      }
      setOrder(order);
    },
    [dispatch]
  );

  useEffect(() => {
    if (basket.items && basket.items.length !== 0) {
      dispatch(resetBasket());
    }
    const orderId = getOrderId();
    const transactionId = getTransactionId();
    if (!order && orderId) {
      fetchOrder(orderId, transactionId);
    }
  }, [dispatch, basket, fetchOrder, order]);

  useEffect(() => {
    if (order) {
      analytics.sendOrderEvent(order);
    }
  }, [order]);

  if (!order) {
    return null;
  }

  if (order.shipments.length > 0) {
    order.shipments[0].first = true;
  } else if (order.seriesItems.length > 0) {
    order.seriesItems[0].first = true;
  }

  const officeId = order.shipments.find(sh => !!sh.departurePoint)?.departurePoint?.officeCode;
  const serviceCodes = serviceCodesForShipments(order.shipments);
  const showP2 = serviceCodes.includes('P2') && !serviceCodes.includes('-EP');
  const hasExpressParcel = order.shipments.some(sh => shipmentIsExpress(sh));
  const showPickupInfo = !!order.pickup;

  return (
    <Layout
      title={translate('buyParcel.confirmation.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container sx={{ mt: 4 }}>
        <Styled.h1 sx={{ color: 'secondary' }}>{translate('buyParcel.confirmation.title')}</Styled.h1>
        <ConfirmationText order={order} />
        {order.shipments.map(s => (
          <Shipment key={s.rowId} row={s} onlyOne={order.shipments.length === 1} pickup={order.pickup} />
        ))}
        {order.seriesItems.map(s => (
          <SeriesItem key={s.rowId} row={s} onlyOne={order.seriesItems.length === 1} />
        ))}
        {showPickupInfo && <PickupDetails pickup={order.pickup} sender={order.sender} />}
      </Container>
      {!showPickupInfo && <MapAndFilters {...{ serviceCodes, showP2, officeId }} />}
    </Layout>
  );
};

const ExpressFeedback = () => {
  const translate = useTranslate();
  return (
    <p sx={{ mt: 0, mb: 4 }}>
      {translate('buyParcel.confirmation.expressFeedback')}{' '}
      <Link to={translate('buyParcel.confirmation.expressFeedbackLink')} target="_blank" rel="noreferrer">
        {translate('buyParcel.confirmation.expressFeedbackForm')}
      </Link>
    </p>
  );
};

const PickupDetails = ({ pickup, sender }) => {
  const translate = useTranslate();
  const address = streetAndApartment.combine(sender);
  return (
    <div sx={{ my: 2, mb: 4 }}>
      <hr />
      <p sx={{ fontWeight: 'medium', mb: 2 }}>{translate('buyParcel.confirmation.pickupAddress')}</p>
      <p sx={{ mt: 2 }}>
        {address}
        <br />
        {sender.postcode} {sender.city}
      </p>
      <Box sx={{ bg: 'blueLighter', my: 3, p: 3, borderRadius: 1, maxWidth: 800 }}>
        {translate('basket.pickup.note1')}
        <br />
        <br />
        {translate('basket.pickup.note2')}
      </Box>
    </div>
  );
};

const shipmentIsExpress = sh => {
  return (sh.services || []).some(sv => sv.serviceCode === 'EXP');
};

const ConfirmationText = ({ order }) => {
  const { pickup } = order;
  const translate = useTranslate();
  const numberOfShipments = order.shipments.length;
  const numberOfSeriesItems = order.seriesItems.length;
  const hasRepack = order.shipments.some(s => s.product.sizeCode === SizeCode.REPACK);
  const onlyExpressParcels = order?.shipments?.length ? order.shipments.every(sh => shipmentIsExpress(sh)) : false;

  return (
    <>
      <p>
        {translate('buyParcel.confirmation.emailSent')}
        {onlyExpressParcels && ' ' + translate('buyParcel.confirmation.writeCode')}
      </p>
      {pickup && (
        <p>
          {translate('buyParcel.confirmation.pickup')}
          <br />
        </p>
      )}
      {onlyExpressParcels && (
        <Box sx={{ p: 2, mt: 3, mb: 2, bg: 'paleYellow' }}>{translate('buyParcel.express.orderProcessed')}</Box>
      )}
      {numberOfShipments > 0 && !pickup && !onlyExpressParcels ? (
        <>
          <p>
            {hasRepack ? (
              <>{translate('buyParcel.confirmation.repack')}</>
            ) : (
              <>{translate('buyParcel.confirmation.shipment')}</>
            )}
            <br />
          </p>
          <p>{translate('buyParcel.confirmation.bringToServicePointExplanation')}</p>
        </>
      ) : null}
      {numberOfSeriesItems > 0 ? (
        <p>
          {translate('buyParcel.confirmation.seriesItem')}{' '}
          <Link to="/multi-parcel">{translate('buyParcel.confirmation.seriesItemLink')}</Link>
          .
          <br />
        </p>
      ) : null}
    </>
  );
};

const ExpressNote = ({ departurePoint }) => {
  const translate = useTranslate();
  const { officeName, officeStreetAddress, officePostalCode, officeCity } = departurePoint || {};
  if (!officeName) return null;
  const address = `${capitalize(officeStreetAddress)}, ${officePostalCode} ${capitalize(officeCity)}`;

  return (
    <YellowNote>
      <div sx={{ flexGrow: 1, mr: 3 }}>
        {translate('buyParcel.confirmation.express', { name: officeName, address })}
      </div>
    </YellowNote>
  );
};

const Shipment = ({ row, onlyOne, pickup }) => {
  const translate = useTranslate();
  const separator = row.first ? {} : { borderTop: '1px solid', borderColor: 'grayLight' };
  const { product, services } = row;
  const item = combineProps(product, services);
  const specialOrOver30 = largeSizeCodes.includes(item.sizeCode) || item.weightClass > 30;
  const isExpressParcel = item.serviceCode === '21' && !!row.departurePoint;

  return (
    <div sx={{ py: [3], ...separator }}>
      <Flex sx={{ alignItems: 'center' }}>
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 48,
            height: 48,
            mr: 2,
          }}
        >
          <img
            sx={{
              alignSelf: 'center',
              transform: 'scale(.67)',
            }}
            src={imagePathForProduct(item.sizeCode)}
            alt="product"
          />
        </Flex>
        <div>
          {onlyOne ? null : (
            <>
              <Recipient {...{ recipient: row.recipient }} />
              <Product product={item} />
            </>
          )}
          <strong>
            {row.activationCode ? (
              `${translate('buyParcel.confirmation.activationCode')} ${row.activationCode}`
            ) : (
              <>
                {translate('buyParcel.confirmation.codeError')} {translate('buyParcel.confirmation.tel')}{' '}
                <a href="tel:+35820336161">020 336 161</a>
              </>
            )}
          </strong>
          {item.sizeCode === SizeCode.TIRESET ? <div>{translate('buyParcel.product.tireDescription')}</div> : null}
        </div>
      </Flex>
      {isExpressParcel ? (
        <ExpressNote departurePoint={row.departurePoint} />
      ) : pickup ? null : specialOrOver30 ? (
        <YellowNote>
          <div sx={{ flexGrow: 1, mr: 3 }}>{translate('buyParcel.confirmation.note3')}</div>
        </YellowNote>
      ) : requiresMHorAgentToSend(item, services) && item.sizeCode !== SizeCode.REPACK ? (
        <YellowNote>
          <div sx={{ flexGrow: 1, mr: 3 }}>{translate('buyParcel.confirmation.note1')}</div>
          <Tooltip
            placement="right"
            tooltip={
              <div>
                <p sx={{ color: 'white' }}>{translate('buyParcel.confirmation.note1.tooltip1')}</p>
              </div>
            }
          >
            <InfoIcon />
          </Tooltip>
        </YellowNote>
      ) : item.isLarge ? (
        <YellowNote>
          <div sx={{ flexGrow: 1, mr: 3 }}>{translate('buyParcel.confirmation.note2')}</div>
          <Tooltip
            placement="right"
            tooltip={
              <div>
                <p sx={{ color: 'white' }}>{translate('buyParcel.confirmation.note2.tooltip1')}</p>
              </div>
            }
          >
            <InfoIcon />
          </Tooltip>
        </YellowNote>
      ) : null}
    </div>
  );
};

const SeriesItem = ({ row, onlyOne }) => {
  const language = useSelector(state => getLanguage(state));
  const translate = useTranslate();
  const separator = row.first ? {} : { borderTop: '1px solid', borderColor: 'grayLight' };
  const displayName = (row.product.localizedName || {})[language] || row.product.displayName;

  return (
    <div sx={{ my: [3], ...separator }}>
      {onlyOne ? null : (
        <>
          {translate('buyParcel.product.seriesShipment')}
          <br />
          10 x {displayName}
          {weightText4product(row.product, translate)}
          <br />
        </>
      )}
      <strong>
        {translate('buyParcel.confirmation.seriesNumber')}&nbsp; {row.productId}
      </strong>
    </div>
  );
};

const Recipient = ({ recipient }) => {
  return <div>{recipient.name}</div>;
};

const Product = ({ product }) => {
  const language = useSelector(state => getLanguage(state));
  const translate = useTranslate();
  const displayName = (product.localizedName || {})[language] || product.displayName;

  return (
    <div>
      {displayName}
      {weightText4product(product, translate)}
    </div>
  );
};

const YellowNote = ({ children, bg }) => {
  return (
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        bg: bg || 'paleYellow',
        py: 2,
        px: 3,
        borderRadius: 1,
        mt: 2,
        mb: 3,
        lineHeight: 1.25,
      }}
    >
      {children}
    </div>
  );
};
